import React, { Suspense } from "react";
import "./App.scss";
import { Route, Routes, Outlet } from "react-router-dom";
import Loading from "./views/pages/Loading/Loading.jsx";

// User Page
const Home = React.lazy(() => import("./views/pages/User/Home/Home"));
const User = React.lazy(() => import("./views/pages/User/User"));
const Cabang = React.lazy(() => import("./views/pages/User/Cabang/Cabang"));
const NotFound = React.lazy(() => import("./views/pages/NotFound/NotFound"));
const Layanan = React.lazy(() => import("./views/pages/User/Layanan/Layanan"));
const JenisTransaksi = React.lazy(() =>
	import("./views/pages/User/JenisTransaksi/JenisTransaksi")
);
const Eticket = React.lazy(() => import("./views/pages/User/Eticket/Eticket"));
// Router
const RouteUser = React.lazy(() => import("./routes/RouteUser"));
// Layout
const LayoutUser = React.lazy(() =>
	import("./views/containers/User/LayoutUser")
);

export default function App() {
	return (
		<Suspense fallback={<Loading />}>
			<Routes>
				<Route path="/" element={<Homepage />}>
					<Route element={<RouteUser />}>
						<Route index element={<Home />} />
						<Route path="/pilih-kota" element={<User />} />
						<Route element={<LayoutUser />}>
							<Route path="/cabang" element={<Cabang />} />
							<Route path="/layanan" element={<Layanan />} />
							<Route path="/jenistransaksi" element={<JenisTransaksi />} />
							<Route path="/eticket/:tgl/:kdBooking" element={<Eticket />} />
						</Route>
					</Route>
					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</Suspense>
	);
}

function Homepage() {
	return (
		<div style={{ width: "100%", overflow: "hidden" }}>
			<Outlet />
		</div>
	);
}
